.container {
  display: flex;
  margin: 0 -5px 10px;
}

.option {
  composes: deliveryTypeOptionBoxDarkBase from global;
  composes: lighterBackgroundColor from global;
  composes: backgroundBorderColor from global;
  color: #333 !important;
  cursor: pointer;
  flex: 1;
  text-align: center;
  margin: 5px;
  font-size: 14px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  padding: 15px 5px;
}

.selected {
  composes: option;
  composes: deliveryTypeOptionBoxDark from global;
  border-color: #c1c1c1 !important;
}

.icon {
}

.label {
  margin-top: 5px;
}
