 .container {
  padding: 60px 0;
  box-sizing: border-box;
  min-height: calc(80vh - 70px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  color: #fff;
  text-align: center;
  background-position: center;
  background-size: cover;
}

 .containerBox {
  background-position: center;
  background-size: cover;
}

 .boxContainer {
  padding: 60px 0;
  box-sizing: border-box;
  min-height: calc(80vh - 70px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

 .title {
  margin-top: -40px;
  color: #fff;
  font-size: 40px;
  composes: appTitle from global;
}

 .box {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 420px;
  max-width: 100%;
  composes: shadow from global;
}

 .titleBox {
  color: #111;
  font-size: 30px;
  padding-top: 0px;
  composes: appTitle from global;
}

 .content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

 .container .label {
  opacity: 0.9;
  font-size: 14px;
}

 .form {
  color: #111;
  text-align: left;
  width: 400px;
  max-width: 100%;
  margin-top: -10px;
}

 .overlay {
  z-index: 1;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

 .button {
  margin-top: 10px;
  composes: buttonShadow from global;
  width: 320px;
  height: 46px;
  color: #fff;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-weight: bold;
}

 .box .button {
  width: 100%;
  border: none;
  background-color: #2866ff;
}
