.container {
  display: flex;
  margin: 0 -5px 10px;
}

.option {
  composes: deliveryTypeOptionBoxLight from global;
  cursor: pointer;
  flex: 1;
  text-align: center;
  margin: 5px;
  font-size: 14px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  padding: 15px 5px;
  border-color: #ffffff55;
  box-shadow: none;
  composes: startOrderingOption from global;
}

.selected {
  composes: option;
  composes: startOrderingSelectedOption from global;
  border-color: #fff;
  box-shadow: 0 0 10px #ffffff55;
}

.icon {
}

.label {
  margin-top: 5px;
}
